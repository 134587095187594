<script>
  import SectionHeading from "../2_level/SectionHeading.svelte";
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
  import FeatureTitleText from "../2_level/FeatureTitleText.svelte";

  export let weAreData;

  let biggestHeightOfImage = 0;

  weAreData.we_deliver.items.map((weDeliverItem) => {
    if (weDeliverItem.image.meta.height > biggestHeightOfImage) {
      biggestHeightOfImage = weDeliverItem.image.meta.height;
    }
  });
</script>

<div class="container-fluid we-are" id="we-are">
  <div class="container">
    <!--<div class="row we-are--row">
      <div class="col-xl-6">
        <SectionHeading title={weAreData.title} />
      </div>
      <div class="col-xl-6 we-are--title-description">
        {weAreData.description}
      </div>
    </div>-->
    <div class="row we-know--row">
      <div class="col-lg-4">
        <SmallSectionHeading title={weAreData.we_know.title} />
      </div>
      <div class="col-lg-8">
        <div class="row">
          {#each weAreData.we_know.items as weKnow, i}
            <FeatureTitleText featureData={weKnow} imagePosition="first" />
          {/each}
        </div>
      </div>
    </div>
    <div class="row we-deliver--row">
      <div class="col-lg-4" style="padding-top: {biggestHeightOfImage + 30}px">
        <SmallSectionHeading title={weAreData.we_deliver.title} />
      </div>
      <div class="col-lg-8">
        <div class="row">
          {#each weAreData.we_deliver.items as weDeliver, i}
            <FeatureTitleText featureData={weDeliver} imagePosition="first" />
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .we-are {
    padding: 120px 0;

    &--row {
      margin-bottom: 90px;
    }

    &--title-description {
      display: flex;
      align-items: center;
      padding-top: 15px;
      padding-left: 30px;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .we-are {
      padding: 90px 0;

      &--row {
        margin-bottom: 90px;
      }
      .we-know {
        &--row {
          margin-bottom: 90px;
        }
      }
      .we-deliver {
        &--row {
          & > .col-lg-4 {
            padding-top: 0 !important;
          }
        }
      }
    }
  }
</style>

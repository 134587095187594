<script>
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";

  export let ourPeopleData = {};

  let scrollY;
  let initialScrollY;
  let expanded = false;
  let swiperContainer;
  let mySwiper;
  let carouselImageHeight;

  onMount(() => {
    mySwiper = new Swiper(swiperContainer, {
      speed: 400,
      spaceBetween: 100,
      slidesPerView: 1,
      loop: true,
    });
  });

  const scrollTo = (position) => (scrollY = position);
  const showMoreOrLessClicked = () => {
    expanded = !expanded;
    expanded
      ? (initialScrollY = scrollY)
      : scrollY > initialScrollY
        ? scrollTo(initialScrollY)
        : null;
  };
  const scrollIntoView = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const slidePrev = () => mySwiper.slidePrev();
  const slideNext = () => mySwiper.slideNext();
  const slideTo = (index) => mySwiper.slideTo(index + 1);
</script>

<svelte:window bind:scrollY />

<section class="our_people container-fluid" id="our-people">
  <div class="container p-0">
    <div class="our_people--title">
      <SmallSectionHeading title={ourPeopleData.title} />
    </div>
    <div class="our_people--carousel">
      <div bind:this={swiperContainer} class="swiper-container">
        <div class="swiper-wrapper">
          {#each ourPeopleData.items as item, i}
            <div class="swiper-slide our_people--carousel-slide">
              <div
                bind:clientHeight={carouselImageHeight}
                class="our_people--carousel-image"
              >
                <img
                  alt={item.image.meta.alt}
                  src={item.image.url}
                  loading="lazy"
                />
              </div>
              <div class="our_people--carousel-content">
                <div class="testing">
                  <div class="our_people--carousel-name">{item.name}</div>
                  <div class="our_people--carousel-title pb-4">
                    {item.job_name}
                  </div>
                  <div class="our_people--carousel-text">
                    {@html item.about}
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>

      <div
        class="swiper-button-prev"
        on:click={() => slidePrev()}
        style="top: {carouselImageHeight / 2}px"
      />
      <div
        class="swiper-button-next"
        on:click={() => slideNext()}
        style="top: {carouselImageHeight / 2}px"
      />
    </div>

    <div class="our_people--gallery">
      {#each ourPeopleData.items as item, i}
        <div
          class="wrapper"
          class:wrapper--expanded={expanded}
          on:click={() => {
            slideTo(i);
            scrollIntoView("our-people");
          }}
        >
          <div class="our_people--gallery-image">
            <img
              alt={item.image.meta.alt}
              src={item.image.url}
              loading="lazy"
            />
          </div>
          <div class="our_people--gallery-name">{item.name}</div>
          <div class="our_people--gallery-title">{item.job_name}</div>
        </div>
      {/each}
    </div>

    <div
      class="our_people--show-more mt-4"
      on:click|preventDefault={showMoreOrLessClicked}
    >
      <div class="py-2 our_people--show-more-cell">
        <img
          alt="bwt"
          class="w-auto"
          src="../../images/Line1.webp"
          loading="lazy"
        />
      </div>
      <div
        class="py-2 px-4 our_people--show-more-cell"
        style="white-space: nowrap; cursor: pointer"
      >
        {#if !expanded}SHOW MORE{:else}SHOW LESS{/if}
      </div>
      <div class="py-2 our_people--show-more-cell">
        <img
          class="our_people--show-more-cell--arrow"
          class:our_people--show-more-cell--arrow-up={expanded}
          alt="bwt"
          src="../../images/Arrow1.webp"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "static/breakpoints";
  @mixin galleryGridLayout($itemsPerRow, $visibleItems) {
    &--gallery {
      grid-template-columns: repeat($itemsPerRow, minmax(0, 1fr));
      .wrapper:nth-child(-n + #{$visibleItems}) {
        display: block;
      }
    }
  }

  .our_people {
    margin-top: 120px;
    padding-bottom: 120px;
    &--title {
      padding-bottom: 50px;
    }
    &--carousel,
    &--gallery {
      margin-bottom: 60px;
      &-image {
        img {
          max-width: 100%;
        }
      }
      &-name {
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: 600;
        color: var(--secondary);
      }
      &-title {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--grey);
      }
    }
    &--carousel {
      position: relative;
      &-content {
        margin: 30px 0 7px;
        background: var(--white-variant);
        padding-left: 20px;
      }
      &-slide {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        // gap: 20px;
      }
    }
    &--gallery {
      display: grid;
      align-items: start;
      gap: 16px;
      margin-bottom: 0;
      word-break: break-word;
      text-align: center;
      .wrapper {
        display: none;
        cursor: pointer;
        &--expanded {
          display: block;
        }
      }
      &-image {
        padding-bottom: 10px;
      }
      &-name {
        font-size: 0.85em;
      }
      &-title {
        font-size: 0.65em;
      }
    }
    &--show-more {
      color: var(--primary);
      font-weight: 600;
      &-cell {
        display: table-cell;
        vertical-align: middle;
        text-transform: uppercase;
        img {
          max-width: 100%;
          min-width: 22px;
        }
        &--arrow {
          transform: rotate(90deg);
          transition: transform 0.3s ease-in-out;
          &-up {
            transform: rotate(-90deg);
          }
        }
      }
    }

    // Applying v2 redesign
    @media only screen and (min-width: 992.1px) {
      .our_people--carousel-content {
        position: relative;
        margin: 30px 0 6.5px;
      }
      .testing {
        height: calc(100% - 4em);
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        background: var(--white-variant);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 2em 1em;
        mask-image: linear-gradient(
          to bottom,
          transparent 0%,
          black 2em,
          black calc(100% - 2em),
          transparent 100%
        );
        /* For Firefox and offshoots */
        scrollbar-color: var(--primary) transparent;
        scrollbar-width: thin;
        /* For Safari, Chrome, and offshoots */
        &::-webkit-scrollbar {
          width: 0.4em;
          overflow-x: hidden;
        }
        &::-webkit-scrollbar-track {
          background-color: var(--primary);
          margin-block: 2em;
          border-radius: 0.2em;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--primary-variant);
          border-radius: 0.2em;
        }
      }
      .our_people--carousel-image {
        height: fit-content;
        &::before {
          background-color: var(--primary);
          content: "";
          width: 100%;
          height: calc(100% - 37px);
          position: absolute;
          top: 0px;
          z-index: -1;
          left: 0;
          right: 0;
          margin: 30px auto auto auto;
        }
      }
    }
    @media only screen and (max-width: breakpoints.$screen-lg) {
      .our_people--carousel-image {
        position: relative;
        margin-top: 2em;
      }
      .our_people--carousel-content {
        padding: 2em 1em;
        margin: -6px 0 0 0;
      }
    }
    // End of v2 redesign

    @media only screen and (min-width: breakpoints.$screen-lg) and (max-width: breakpoints.$screen-xl-with-swiper-buttons) {
      &--carousel {
        padding: 0 32px;
      }
    }

    @media only screen and (max-width: breakpoints.$screen-lg) {
      &--title {
        padding-bottom: 0;
      }
      &--carousel {
        &-content {
          padding-top: 24px;
        }
        &-slide {
          display: block;
        }
        &-image {
          text-align: center;
        }
      }
      &--gallery {
        &-name {
          font-size: 1em;
        }
        &-title {
          font-size: 0.75em;
        }
      }
    }

    @media only screen and (min-width: breakpoints.$screen-md) {
      @include galleryGridLayout(8, 8);
    }

    @media only screen and (min-width: breakpoints.$screen-sm) and (max-width: breakpoints.$screen-md) {
      @include galleryGridLayout(4, 8);
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
      @include galleryGridLayout(2, 4);
    }
  }
</style>

<script>
  import { onMount } from "svelte";
  import Swiper, { Autoplay } from "swiper";
  import isDataLoaded from "../../_tools/DataLoaded.js";

  export let employerData = {};

  let swiperContainer;
  let mySwiper;

  Swiper.use([Autoplay]);
  onMount(() => {
    mySwiper = new Swiper(swiperContainer, {
      speed: 400,
      spaceBetween: 35,
      slidesPerView: 2,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      loop: true,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 65,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 100,
        },
      },
    });
  });

  const slidePrev = () => mySwiper.slidePrev();
  const slideNext = () => mySwiper.slideNext();
</script>

<div class="container-fluid employer" id="employer">
  <div
    class="employer--pseudo-bg"
    style="background-image: url({employerData.image.url})"
  ></div>

  <div class="container">
    <div class="row employer-intro--row col-lg-6">
      <div class="mb-2">
        <div class="employer-intro--title">
          {employerData.title}
        </div>
        <div class="employer-intro--message">
          <p>{employerData.description}</p>
        </div>
      </div>
      <div class="mb-2">
        <div class="employer-note--title">
          {employerData.thesis1.title}
        </div>
        <div class="employer-note--message">
          <ul>
            {#each employerData.thesis1.items as item}
              <li>{item}</li>
            {/each}
          </ul>
        </div>
      </div>
      <div class="mb-2">
        <div class="employer-note--title">
          {employerData.thesis2.title}
        </div>
        <div class="employer-note--message">
          <ul>
            {#each employerData.thesis2.items as item}
              <li>{item}</li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

{#if isDataLoaded(employerData.logos)}
  <div class="employer-slider">
    <div class="container employer-slider-wrapper">
      <div bind:this={swiperContainer} class="swiper-container py-2">
        <div class="swiper-wrapper">
          {#each employerData.logos as imgData}
            <div class="swiper-slide">
              <img
                alt={imgData.image.meta.alt}
                src={imgData.image.url}
                loading="lazy"
              />
            </div>
          {/each}
        </div>
      </div>
      <div class="swiper-button-prev" on:click={() => slidePrev()} />
      <div class="swiper-button-next" on:click={() => slideNext()} />
    </div>
  </div>
{/if}

<style lang="scss">
  @use "static/breakpoints";

  ul {
    padding: 0;
    list-style: none;
  }

  ul li {
    margin-bottom: 0.5em;
  }

  .employer {
    background-color: var(--primary);
    color: var(--white);
    position: relative;
    &--pseudo-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      background-size: cover;
      z-index: 1;
    }
    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100%;
      position: relative;
      z-index: 3;
      padding-bottom: 2em;
      padding-top: 2em;
    }
    &-intro {
      &--row {
        width: 100%;
        flex-direction: column;
      }
      &--title {
        font-size: 3em;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 0.3em;
        color: var(--primary-variant);
      }
      &--message {
        font-weight: 500;
        font-size: 1.05em;
        font-family: var(--font-family-maven);
      }
    }
    &-note {
      &--title {
        font-size: 1.1em;
        text-transform: uppercase;
        font-weight: 500;
        font-family: var(--font-family-maven);
      }
      &--message {
        font-size: 1.1em;
        font-family: var(--font-family-maven);
        ul {
          margin: 0.3em 0 1em;
        }
      }
    }
    &-slider {
      background-color: var(--primary-light);
      &-wrapper {
        position: relative;
        .swiper-slide {
          height: 120px;
          img {
            width: 100%;
            height: 110px;
            padding-top: 5px;
            object-fit: contain;
          }
        }
      }
    }
  }
  .employer::before {
    display: block;
    position: absolute;
    content: " ";
    background: #0e76bd66;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  @media only screen and (max-width: breakpoints.$screen-xl-with-swiper-buttons) {
    .employer-slider-wrapper {
      padding: 0 1.8em;
    }
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .employer {
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100%;
        position: relative;
        z-index: 3;
        padding-bottom: 30px;
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .employer {
      height: auto;
      padding: 0;
      &--pseudo-bg {
        position: relative;
        min-height: 13em;
        margin-bottom: 1em;
        &::before {
          display: block;
          position: absolute;
          content: " ";
          background: #0e76bd66;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
      }
      &--award-img {
        height: 25vw;
        width: auto;
      }
      .container {
        display: block;
        padding-bottom: 0;
      }
      &-intro {
        &--title {
          font-size: 2.5em;
          margin-bottom: 15px;
        }
      }
      &-note {
        &--message ul {
          margin-top: 5px;
        }
        &--message li {
          margin-bottom: 5px;
        }
      }
    }
    .employer::before {
      display: none;
    }
  }
</style>

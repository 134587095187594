<script>
  export let value;
</script>

<div class="value">
  <h4>{value.title}</h4>
  <div class="separator"></div>
  <div class="text">{@html value.text}</div>
  <a href="/culture">
    <img alt={value.image.meta.alt} src={value.image.url} loading="lazy" />
  </a>
</div>

<style lang="scss">
  @use "static/breakpoints";

  .value {
    background-color: var(--white-variant);
    padding: 30px;
    position: relative;
    h4 {
      font-size: 32px;
      margin: 0;
      text-transform: uppercase;
      padding-right: 70px;
    }
    .separator {
      width: 30px;
      height: 5px;
      background-color: var(--primary-dark);
      margin-top: 3px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      padding: 0 0 12px 0;
    }
    img {
      position: absolute;
      top: -20px;
      right: -18px;
      width: 120px;
      height: auto;
      transition: ease-in-out 0.75s;
      &:hover {
        transform: translateX(-25px);
      }
    }
  }

  // Replacing dot with dash for li in ul
  :global(.our-values .value .text ul) {
    list-style-type: none;
    padding-inline-start: 0;
  }
  :global(.our-values .value .text ul > li:before) {
    content: "—";
    padding-right: 5px;
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .value {
      h4 {
        font-size: 25px;
        padding-right: 55px;
      }
      img {
        width: 100px;
      }
      .text {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
</style>

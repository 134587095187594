<script>
  export let title;

  title = title.replace("_", "<span class='blue'>_</span>");
</script>

<div class="section-title">{@html title}</div>

<style lang="scss">
  @use "static/breakpoints";
  .section-title {
    font-size: 80px;
    font-weight: 900;
    color: var(--white-variant);
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .section-title {
      font-size: 60px;
      line-height: 1;
      margin-bottom: 30px;
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .section-title {
      font-size: 2.75em;
    }
  }
</style>

<script>
  import OurValuesCard from "../2_level/OurValuesCard.svelte";
  import SectionHeadingV2 from "../2_level/SectionHeadingV2.svelte";

  export let ourValuesData;
</script>

<div class="container-fluid our-values" id="our-values">
  <div class="container">
    <SectionHeadingV2 title={ourValuesData.title} />
    <div class="grid">
      {#each ourValuesData.items as value}
        <OurValuesCard {value} />
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";

  .our-values {
    margin-top: 3em;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5em;
      margin-top: 1em;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .our-values .grid {
      grid-template-columns: 1fr;
    }
  }
</style>

<script>
  export let block;
</script>

<div class="offer-block row m-0">
  <div class="image-holder">
    <img alt="bwt" src={block.image.url} loading="lazy" />
  </div>
  <div class="p-3 text-holder">
    <b>{block.title}</b>
    <span class="blue-bar" />
    <p>{block.text}</p>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";

  .offer-block {
    background-color: var(--white);
    flex-wrap: nowrap;
    column-gap: 20px;
    align-items: center;
    .image-holder {
      flex: 0 0 14em;
      max-height: 11.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .blue-bar {
      background-color: var(--primary);
      height: 5px;
      width: 30px;
      margin-top: 14px;
      display: block;
    }
    .text-holder {
      display: flex;
      flex-direction: column;
      align-self: center;
      b {
        text-transform: uppercase;
        font-size: 1.35rem;
        font-weight: 800;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .offer-block {
      flex-direction: column;
      .image-holder {
        width: 50%;
        margin: 0 auto 1em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xs) {
    .offer-block {
      .image-holder {
        width: 80%;
      }
    }
  }
</style>

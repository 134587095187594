<script>
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
  import OfferBlock from "../2_level/OfferBlock.svelte";
  import ShowMoreLine from "../2_level/ShowMoreLine.svelte";
  import FeatureTitleText from "../2_level/FeatureTitleText.svelte";
  export let weValueData;
</script>

<div class="container-fluid we-value-v2" id="we-value">
  <div class="container">
    <div class="row we-value-v2-row">
      <div class="title">{weValueData.title}</div>
      <div class="blocks">
        {#each weValueData.items as weValue}
          <OfferBlock block={weValue} />
        {/each}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";

  .we-value-v2 {
    padding-top: 5em;
    padding-bottom: 4em;
    background-color: var(--white-variant);
    &-row {
      position: relative;
      overflow: hidden;
      align-items: center;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
    }
    .title {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      flex-basis: fit-content;
      color: var(--white);
      font-size: 6.5em;
      font-weight: 900;
      margin-left: 0.1em;
    }
    .blocks {
      display: flex;
      flex-direction: column;
      row-gap: 1.5em;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xl) {
    .image-holder {
      flex: 0 0 10em;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .we-value-v2 {
      &-row {
        flex-direction: row;
        flex-wrap: wrap;
        overflow: unset;
      }
      .title {
        writing-mode: unset;
        text-orientation: unset;
        margin-left: unset;
        margin-bottom: 0.1em;
        font-size: 5.3em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-sm) {
    .we-value-v2 {
      .title {
        font-size: 4em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xs) {
    .we-value-v2 {
      .title {
        font-size: 3em;
      }
    }
  }
</style>
